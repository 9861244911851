/* =================================
------------------------------------
  HALO - Photography Portfolio
  Version: 1.0
 ------------------------------------ 
 ====================================*/

/*----------------------------------------*/
/* Template modified CSS by GemTexch
/*----------------------------------------*/

html,
body {
  height: 100%;
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #f9f6f6;
  margin: 0;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

p {
  font-size: 14.2px;
  color: #eeecec;
  line-height: 2.2;
}

img {
  max-width: 100%;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
  outline: none;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

/*---------------------
	Helper CSS
-----------------------*/
.spad {
  padding-top: 10px;
}

.sp-pad {
  padding-left: 150px;
  padding-right: 150px;
}

.sp-sub-title {
  color: #868686;
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.sp-title {
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 45px;
  letter-spacing: 6px;
}

.section-title h2 {
  display: inline-block;
  position: relative;
  margin-bottom: 110px;
  padding-bottom: 2px;
  line-height: normal;
}

.section-title h2:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  background: #40424a;
}

.set-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;
}

/*------------------------
  Common Elements
--------------------------*/
.element {
  margin-bottom: 150px;
}

.element .sp-title {
  margin-bottom: 70px;
}

.element:last-child {
  margin-bottom: 0;
}

.element .site-btn {
  margin-bottom: 10px;
}

/* Preloder */

#preloder {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #fff;
}

.loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -13px;
  border-radius: 60px;
  animation: loader 0.8s linear infinite;
  -webkit-animation: loader 0.8s linear infinite;
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
  50% {
    -webkit-transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent;
  }
  100% {
    -webkit-transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
}

/* Site Buttons */
.site-btn {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  min-width: 135px;
  font-weight: 600;
  padding: 18px 10px 20px;
  color: #f9f6f6;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: center;
  border-top: 2px solid gold;
  border-bottom: 2px solid goldenrod;
}

.site-btn:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 2px;
  left: 0;
  background: #222121;
}

.site-btn.light {
  color: #fff;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.site-btn.light:after {
  background: #fff;
}

.site-btn.light:hover {
  color: #fff;
}

.site-btn:hover {
  font-size: large;
  color: rgb(251, 207, 251);
}

.site-btn.btn-two {
  border-left: 2px solid #222121;
  border-right: 2px solid #222121;
}

.site-btn.btn-three {
  padding: 19px 10px 19px;
}

.site-btn.btn-three:after {
  display: none;
}

.site-btn.btn-four {
  padding: 19px 10px 19px;
  border-left: 2px solid #222121;
  border-right: 2px solid #222121;
}

.site-btn.btn-four:after {
  display: none;
}

.arrow-btn {
  color: #7a7a7a;
  font-size: 20px;
}

.arrow-btn i {
  display: inline-block;
}

.arrow-btn:hover {
  color: #7a7a7a;
}

/* Circle progress */
.circle-progress {
  text-align: center;
  display: block;
  position: relative;
  margin-bottom: 30px;
}

.circle-progress canvas {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.circle-progress .progress-info {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -45px;
}

.circle-progress .progress-info h2 {
  margin-bottom: 5px;
}

.circle-progress .progress-info p {
  font-size: 14px;
  margin-bottom: 0;
  color: #222121;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
}

/* Fact box */
.fact-box .fact-content {
  text-align: center;
  position: relative;
}

.fact-box .fact-content:after {
  position: absolute;
  content: "";
  height: 90px;
  width: 2px;
  background: #f3f3f3;
  right: 0;
  top: 30px;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}

.fact-box .fact-content i {
  color: #d3d3d3;
  font-size: 45px;
  line-height: 45px;
  display: block;
  margin-bottom: 20px;
}

.fact-box .fact-content h2 {
  font-size: 36px;
  margin-bottom: 15px;
}

.fact-box .fact-content p {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 0;
}

.fact-box:last-child .fact-content:after {
  display: none;
}

/* Accordion */
.accordion-area .panel {
  margin-bottom: 18px;
}

.accordion-area .panel-header {
  display: block;
  font-weight: 600;
  position: relative;
  border: 2px solid #000;
  -webkit-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.accordion-area .panel-link:after {
  content: "+";
  position: absolute;
  font-size: 18px;
  font-weight: 700;
  right: 25px;
  top: 50%;
  color: #000;
  line-height: 16px;
  margin-top: -8px;
  margin-left: -4px;
}

.accordion-area .panel-header.active .panel-link:after {
  content: "-";
  margin-top: -10px;
}

.accordion-area .panel-header.active .panel-link.collapsed:after {
  content: "+";
}

.accordion-area .panel-link {
  text-align: left;
  background-color: transparent;
  border: none;
  position: relative;
  width: 100%;
  padding: 15px 40px;
  padding-left: 30px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.accordion-area .panel-body p {
  font-size: 14px;
  margin-bottom: 0;
  padding-top: 25px;
}

.accordion-area .panel-body {
  padding: 0 5px;
}

/* Tab */
.tab-element .nav-tabs {
  border-bottom: 2px solid #222121;
  display: table;
  width: 100%;
}

.tab-element .nav-tabs .nav-item {
  display: table-cell;
  text-align: center;
}

.tab-element .nav-tabs .nav-item:last-child .nav-link {
  margin-right: 0;
}

.tab-element .nav-tabs .nav-link {
  border: 2px solid #222121;
  margin-right: 10px;
  border-radius: 0;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  color: #222121;
  padding: 15px 10px;
}

.tab-element .nav-tabs .nav-link.active {
  padding-bottom: 25px;
  border-bottom: none;
  position: relative;
}

.tab-element .nav-tabs .nav-link.active:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background: #fff;
  bottom: -2px;
  left: 0;
}

.tab-element .tab-pane {
  padding-top: 25px;
}

/* Icon Box */
.icon-box {
  margin-bottom: 70px;
}

.icon-box i {
  display: block;
  font-size: 50px;
  color: #d3d3d3;
  margin-bottom: 30px;
}

.icon-box h4 {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

/*---------------------
	Intro section
-----------------------*/
.intro-text {
  padding-top: 70px;
}

.intro-text p {
  margin-bottom: 50px;
}

.container h3,
.intro-section h3,
.container h4 {
  color: rgb(217, 226, 236);
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.service a:hover {
  text-decoration: none;
}

.service:hover {
  background-color: rgba(255, 255, 255, 0.411);
  box-shadow: 0 1px 18px 0px rgba(0, 0, 0, 1);
}

.service:hover h4 {
  color: #000;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px rgb(255, 255, 255);
}
.service:hover p {
  text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;
  font-weight: bold;
}

.project-heading {
  color: aliceblue;
}

/*---------------------
	Portfolio section
-----------------------*/
.portfolio-section {
  margin-top: 3em;
}
.portfolio-warp {
  position: relative;
  display: block;
  overflow: hidden;
}

.single-portfolio {
  height: 580px;
  width: 33.333333%;
  position: relative;
  float: left;
  display: block;
  background-position: center center;
}

.single-portfolio.sm-wide {
  width: 22.222222%;
}

.single-portfolio:hover .portfolio-info {
  opacity: 1;
}

.portfolio-info {
  position: absolute;
  width: calc(100% - 110px);
  height: calc(100% - 110px);
  left: 55px;
  top: 55px;
  text-align: center;
  padding-top: 220px;
  border: 1px solid #fff;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.portfolio-info .pfbg {
  position: absolute;
  width: 110%;
  height: 110%;
  left: -5%;
  top: -5%;
  -webkit-transform: scale(1.12);
  -ms-transform: scale(1.12);
  transform: scale(1.12);
  -webkit-filter: blur(20px);
  filter: blur(20px);
  background-position: center center;
}

.portfolio-info h5 {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  z-index: 9;
  position: relative;
  margin-bottom: 10px;
}

.portfolio-info p {
  font-size: 14px;
  color: #fff;
  z-index: 9;
  position: relative;
}

.portfolio-filter {
  list-style: none;
}

.portfolio-filter li {
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 12px;
  color: #868686;
  font-weight: 600;
  cursor: pointer;
}

.portfolio-filter li.mixitup-control-active {
  color: #111;
}

/*---------------------
	Milestones section
-----------------------*/
.milestones-section {
  background: #000;
}

.milestones-section .fact-box .fact-content {
  text-align: center;
  position: relative;
}

.milestones-section .fact-box .fact-content:after {
  background: #6f6e6e;
}

.milestones-section .fact-box .fact-content i,
.milestones-section .fact-box .fact-content h2,
.milestones-section .fact-box .fact-content p {
  color: #fff;
}

.milestones-section .fact-box:last-child .fact-content:after {
  display: none;
}

/*---------------------
	Services section
-----------------------*/
.services-section {
  position: relative;
}

.services-warp {
  position: relative;
}

.services-text {
  position: absolute;
  width: 490px;
  left: 140px;
}

.services-text p {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 50px;
}

.service-more-link {
  position: absolute;
  right: 150px;
  top: 50%;
  margin-top: -15px;
}

/*---------------------
	Contact section
-----------------------*/
.contact-section {
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
}

.contact-section .contact-warp {
  position: relative;
  z-index: 2;
}

.contact-section:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
  opacity: 0.7;
}

.contact-text {
  position: absolute;
  width: 490px;
  left: 140px;
}

.contact-text .sp-sub-title,
.contact-text .sp-title,
.contact-text p {
  color: #fff;
}

.contact-text p {
  font-size: 16px;
  margin-bottom: 20px;
}

.contact-text .con-info {
  list-style: none;
}

.contact-text .con-info li {
  color: #fff;
  margin-bottom: 20px;
  font-size: 14px;
}

.contact-text .con-info li i {
  width: 30px;
  float: left;
  color: #b2b0b0;
  display: block;
  margin-right: 25px;
  font-size: 20px;
}

.contact-form {
  padding-top: 80px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: 100%;
  background: none;
  border: none;
  border-bottom: 1px solid #fff;
  color: #fff;
  font-size: 14px;
  padding: 5px 0 20px;
  margin-bottom: 20px;
}

.contact-form textarea {
  height: 130px;
  padding-bottom: 10px;
  margin-bottom: 35px;
}

.contact-form ::-webkit-input-placeholder {
  font-style: italic;
  color: #fff;
}

.contact-form :-ms-input-placeholder {
  font-style: italic;
  color: #fff;
}

.contact-form ::-ms-input-placeholder {
  font-style: italic;
  color: #fff;
}

.contact-form ::placeholder {
  font-style: italic;
  color: #fff;
}

/*---------------------
	Footer section
----------------------*/
.footer-section {
  background: #0d0d0d;
}

.footer-section h2 {
  color: #fff;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 10px;
}

.footer-section span {
  font-size: 14px;
  color: #fff;
  margin-right: 40px;
  margin-bottom: 20px;
}

.footer-section .social a {
  color: #fff;
  margin-right: 40px;
}

.footer-section .social a:last-child {
  margin-right: 0;
}

/*---------------------
	Other Pages
-----------------------
========================*/

.page-top-area {
  height: 300px;
  position: relative;
}

.page-top-area:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
  opacity: 0.7;
}

.breadcrumb-area {
  position: absolute;
  left: 150px;
  bottom: 35px;
  z-index: 2;
  color: #fff;
}

.breadcrumb-area a,
.breadcrumb-area span {
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  margin-right: 15px;
  margin-left: 10px;
}

.breadcrumb-area a:first-child {
  margin-left: 0;
}

/*---------------------
	Progress section
-----------------------*/
.progress-section {
  padding: 0 80px 100px;
}

.progress-section .circle-progress:after {
  position: absolute;
  content: "";
  height: 90px;
  width: 2px;
  background: #d0d0d0;
  right: 0;
  top: 70px;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}

.progress-section .cp-item:last-child .circle-progress:after {
  display: none;
}

/*---------------------
	Review section
-----------------------*/
.review-section {
  position: relative;
}

.review-section:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
  opacity: 0.7;
}

.review-section .review-warp {
  position: relative;
  z-index: 2;
}

.review-section .review-warp .sp-sub-title,
.review-section .review-warp .sp-title {
  color: #fff;
}

.review-slider .rs-item .qute {
  font-size: 30px;
  color: #fff;
}

.review-slider .rs-item p {
  font-size: 24px;
  font-style: italic;
  color: #fff;
  margin-bottom: 50px;
}

.review-slider .rs-item h4 {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 2px;
  padding-top: 50px;
  margin-left: 90px;
  text-transform: uppercase;
}

.review-slider .client-pic {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  overflow: hidden;
  float: left;
}

/*---------------------
	Work nav section
-----------------------*/
.worknav-section {
  padding-top: 90px;
  padding-bottom: 90px;
}

.worknav-section.extra-style {
  background: #f2f7f7;
  padding-top: 150px;
  padding-bottom: 150px;
}

.worknav .wn-thumb {
  width: 165px;
  height: 165px;
  display: inline-block;
  border-radius: 50%;
}

.worknav .wn-info {
  position: relative;
  display: inline-block;
}

.worknav .wn-info span {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #868686;
}

.worknav .wn-info h3 {
  font-size: 24px;
  font-weight: 400;
}

.worknav.wn-prev .wn-info {
  top: -50px;
  margin-left: 50px;
}

.worknav.wn-next {
  text-align: right;
}

.worknav.wn-next .wn-thumb {
  float: right;
}

.worknav.wn-next .wn-info {
  top: 65px;
  margin-right: 50px;
}

/*---------------------
	Blog Page
-----------------------*/
.blog-item {
  margin-bottom: 150px;
}

.blog-item:last-child {
  margin-bottom: 0;
}

.blog-thumb {
  height: 660px;
  margin-bottom: 140px;
  position: relative;
}

.post-date-warp {
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 0;
}

.post-date-warp .post-date-box {
  width: 105px;
  height: 106px;
  background: #fff;
  text-align: center;
  padding-top: 20px;
}

.post-date-warp .post-date-box h3 {
  letter-spacing: 2px;
  margin-bottom: 5px;
}

.post-date-warp .post-date-box p {
  font-size: 12px;
  text-transform: uppercase;
  color: #222121;
  font-weight: 600;
}

.blog-list {
  margin-bottom: 140px;
}

.blog-title {
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 5px;
  font-weight: 700;
}

.post-metas {
  margin-bottom: 60px;
}

.post-metas .post-meta {
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #868686;
  margin-left: 15px;
  margin-right: 15px;
}

.post-metas .post-meta a {
  color: #222121;
}

.post-metas .post-meta:first-child {
  margin-left: 0;
}

/*---------------------
	Single work Page
-----------------------*/
.work-slider {
  margin-bottom: 150px;
}

.work-slider .ws-item {
  height: 660px;
}

.work-slider .owl-nav {
  position: absolute;
  right: 55px;
  top: 50%;
  margin-top: -10px;
}

.work-slider .owl-nav .owl-prev {
  display: none;
}

.work-slider .owl-nav .owl-next {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.work-info-list {
  list-style: none;
}

.work-info-list li {
  display: block;
  width: 50%;
  margin-bottom: 60px;
  float: left;
}

.work-info-list li span {
  font-size: 12px;
  text-transform: uppercase;
  color: #868686;
  margin-bottom: 15px;
  display: block;
  letter-spacing: 1px;
  font-weight: 600;
}

.work-info-list li h4 {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.next-portfolio-link {
  padding-top: 70px;
  padding-bottom: 70px;
  text-align: right;
}

.map-area {
  height: 560px;
}

/*---------------------
	Responsive 
-----------------------*/
@media only screen and (max-width: 1650px) {
  .sp-pad {
    padding-left: 90px;
    padding-right: 90px;
  }
  .hero-slider .owl-dots {
    left: 90px;
  }
  .contact-text,
  .services-text {
    left: 80px;
  }
}

@media only screen and (max-width: 1510px) {
  .sp-pad {
    padding-left: 30px;
    padding-right: 30px;
  }
  .breadcrumb-area,
  .hero-slider .owl-dots {
    left: 30px;
  }
  .contact-text,
  .services-text {
    left: 30px;
    width: 350px;
  }
  .service-more-link {
    right: 30px;
  }
  .progress-section .circle-progress:after {
    display: none;
  }
}

@media only screen and (max-width: 1366px) {
  .hs-itesm {
    min-height: 750px;
  }
  .single-portfolio {
    height: 400px;
  }
  .portfolio-info {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    left: 20px;
    top: 20px;
    padding-top: 165px;
  }
}

/* Medium screen : 992px. */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-text,
  .services-text {
    width: 100%;
    position: relative;
    margin-bottom: 50px;
    left: 0;
  }
  .single-portfolio {
    width: 50%;
  }
  .single-portfolio.sm-wide {
    width: 50%;
  }
  .single-portfolio.sm-wide.md-w-100 {
    width: 100%;
  }
  .single-portfolio .md-w-100 {
    width: 100%;
  }
  .service-more-link {
    right: 0;
    position: relative;
    text-align: center;
  }
  .worknav {
    text-align: center;
  }
  .worknav .wn-thumb,
  .worknav .wn-info {
    display: block;
  }
  .worknav .wn-thumb {
    margin: 0 auto 30px;
  }
  .worknav.wn-prev .wn-info,
  .worknav.wn-next .wn-info {
    top: 0px;
    margin-left: 0px;
    margin-right: 0;
    text-align: center;
  }
  .worknav.wn-next .wn-thumb {
    float: none;
  }
}

/* Tablet :768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .site-logo {
    padding-top: 0;
  }
  .search-top {
    padding: 8px;
    margin-left: 10px;
  }
  .main-menu ul li a {
    padding: 10px;
    margin-right: 5px;
  }
  .hero-slider .owl-nav {
    right: 50px;
  }
  .hs-items {
    min-height: 650px;
  }
  .hs-items .hs-title {
    font-size: 100px;
    margin-bottom: 20px;
  }
  .hs-items .hs-des {
    font-size: 40px;
  }
  .contact-text,
  .services-text {
    width: 100%;
    position: relative;
    margin-bottom: 50px;
    left: 0;
  }
  .single-portfolio {
    width: 50%;
  }
  .single-portfolio.sm-wide {
    width: 50%;
  }
  .single-portfolio.sm-wide.md-w-100 {
    width: 100%;
  }
  .single-portfolio .md-w-100 {
    width: 100%;
  }
  .fact-box .fact-content {
    margin-bottom: 50px;
  }
  .fact-box .fact-content:after {
    display: none;
  }
  .service-more-link {
    right: 0;
    position: relative;
    text-align: center;
  }
  .worknav {
    text-align: center;
  }
  .worknav .wn-thumb,
  .worknav .wn-info {
    display: block;
  }
  .worknav .wn-thumb {
    margin: 0 auto 30px;
  }
  .worknav.wn-prev .wn-info,
  .worknav.wn-next .wn-info {
    top: 0px;
    margin-left: 0px;
    margin-right: 0;
    text-align: center;
  }
  .worknav.wn-next .wn-thumb {
    float: none;
  }
  .blog-thumb {
    height: 550px;
  }
}

/* Large Mobile :480px. */
@media only screen and (max-width: 767px) {
  .search-top {
    display: none;
  }
  .nav-switch {
    position: absolute;
    right: 20px;
    font-size: 30px;
    color: #fff;
    top: 38px;
    display: block;
  }
  .main-menu {
    display: none;
    float: none;
    position: absolute;
    width: 100%;
    background: #222;
    left: 0;
    top: 100%;
  }
  .main-menu ul li {
    display: block;
  }
  .main-menu ul li a {
    display: block;
    padding: 15px;
    margin-right: 0;
    border-bottom: 1px solid #292929;
  }
  .main-menu ul li a:after {
    left: 35px;
  }
  .hero-slider .owl-nav {
    right: 50px;
  }
  .hs-items {
    min-height: 470px;
  }
  .hs-items .hs-title {
    font-size: 80px;
    margin-bottom: 20px;
  }
  .hs-items .hs-des {
    font-size: 30px;
  }
  .sp-pad {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sp-title {
    font-size: 26px;
  }
  .contact-text,
  .services-text {
    width: 100%;
    position: relative;
    margin-bottom: 50px;
    left: 0;
  }
  .single-portfolio {
    width: 100%;
  }
  .single-portfolio.sm-wide {
    width: 100%;
  }
  .single-portfolio.sm-wide.md-w-100 {
    width: 100%;
  }
  .single-portfolio .md-w-100 {
    width: 100%;
  }
  .fact-box .fact-content {
    margin-bottom: 50px;
  }
  .fact-box .fact-content:after {
    display: none;
  }
  .service-more-link {
    right: 0;
    position: relative;
    text-align: center;
  }
  .progress-section {
    padding: 0 15px 100px;
  }
  .worknav {
    text-align: center;
  }
  .worknav .wn-thumb,
  .worknav .wn-info {
    display: block;
  }
  .worknav .wn-thumb h3,
  .worknav .wn-info h3 {
    font-size: 20px;
  }
  .worknav .wn-info h3 {
    font-size: 20px;
  }
  .worknav .wn-thumb {
    width: 100px;
    height: 100px;
    margin: 0 auto 30px;
  }
  .worknav.wn-prev .wn-info,
  .worknav.wn-next .wn-info {
    top: 0px;
    margin-left: 0px;
    margin-right: 0;
    text-align: center;
  }
  .worknav.wn-next .wn-thumb {
    float: none;
  }
  .work-slider .ws-item {
    height: 400px;
  }
  .blog-thumb {
    height: 400px;
  }
}

/* small Mobile :320px. */
@media only screen and (max-width: 479px) {
  .hero-slider .owl-nav {
    right: 20px;
  }
  .hs-items .hs-title {
    font-size: 50px;
    margin-bottom: 20px;
  }
  .hs-items .hs-des {
    font-size: 18px;
  }
  .sp-pad {
    padding-left: 15px;
    padding-right: 15px;
  }
  .worknav .wn-thumb {
    width: 70px;
    height: 70px;
  }
  .worknav .wn-info h3 {
    font-size: 16px;
  }
  .work-info-list li {
    width: 100%;
  }
  .footer-section h2 {
    font-size: 33px;
  }
}
