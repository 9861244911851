.project-container {
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 5em;

  position: relative;
  color: aliceblue;
}

.project-heading {
  font-size: 2.4em;
  font-weight: bold;
}

.project-wrapper {
  margin-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
}

.projects {
  max-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.8em;
  padding: 1em;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s;
}

.projects:hover {
  transition: all 1s;
  border: 1px solid white;
}

#swap-box {
  width: 60vw;
  margin: auto;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0.5em dashed;
}

#swap-box h2 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}
.swapitem {
  width: 40vw;
  padding: 0.7em 5px;
  border-radius: 10px;
  border: 1.5px solid rgb(121, 121, 121);
  background-color: #01b1fda2;
}

.swapitem .swapspan {
  display: flex;
  flex-direction: row;
}

.swapitem .swapspan input {
  border: none;
  background-color: rgba(105, 105, 105, 0.29);
  font-size: 18px;
  line-height: 30px;
  outline: 0;
  font-weight: bold;
  display: block;
  width: 80%;
  color: white;
  margin: auto;
}

.swapitem .swapspan img {
  margin: auto;
  width: 10%;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.swap-action {
  margin: 1em;
}

.swap-action button {
  padding: 0.9em 2em;
  border-radius: 50px;
  border: none;
  background-color: rgb(255, 255, 255);
  color: #01b1fd;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
}

.switch {
  border: none;
  border-radius: 5em;
  background-color: plum;
  margin: 1em auto;
}

@media screen and (min-width: 350px) and (max-width: 540px) {
  .project-container {
    max-width: 410px;
    margin: 0 auto;
    padding-top: 5em;
  }

  .project-heading {
    font-size: 2em;
    font-weight: bold;
  }

  .project-wrapper {
    gap: 2em;
  }

  #swap-box {
    width: 95vw;
  }

  .swapitem {
    width: 70vw;
  }
}

@media screen and (min-width: 600px) and (max-width: 920px) {
  .project-container {
    max-width: 700px;
  }
  #swap-box {
    width: 75vw;
  }

  .swapitem {
    width: 50vw;
  }
}
