.card {
  color: aliceblue;
  width: 22vw;
  height: 300px;
  min-height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    blueviolet,
    rgb(21, 30, 4),
    blueviolet,
    rgb(66, 66, 66)
  );

  margin: auto;
  padding: 0.5em;
  border-radius: 10px;
  box-shadow: 0 1px 15px 0px rgb(255, 255, 255);
}

.card:hover {
  color: aliceblue;
  text-decoration: none;
  animation: slide 1s normal;
  box-shadow: 2px 5px 15px 2px rgb(91, 170, 67);
}

.card img {
  width: 20vw;
}

@keyframes slide {
  from {
    transform: translateX(10px);
  }
}

.card .chain {
  background-color: rgba(0, 0, 0, 0.614);
  border-radius: 3em;
  padding: 0.1em 0.5em;
}

.card .description {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-top: 0.5em;
  line-height: normal;
  text-transform: uppercase;
  font-size: small;
}

.description p {
}

@media screen and (max-width: 598px) {
  .card {
    width: 40vw;
    height: max-content;
  }

  .card img {
    width: 35vw;
  }

  .card .description {
    text-transform: capitalize;
  }
}

@media screen and (min-width: 600px) and (max-width: 1028px) {
  .card {
    width: 23vw;
    height: 350px;
    margin: auto;
  }
}
