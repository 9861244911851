.hero-slider {
  position: relative;
}

.hs-items {
  min-height: 950px;
  position: relative;
  display: table;
  background-image: url("../../Assets/img/hero-slider/1.jpg");
  width: 100%;
}

.hs-items:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgb(0, 0, 0);
  opacity: 0.7;
}

.hs-items .hs-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  z-index: 2;
}

.hs-items .hs-title {
  color: #fff;
  font-size: 130px;
  font-weight: 300;
  margin-bottom: 40px;
  position: relative;
  opacity: 10;
}

.hs-items .hs-des {
  color: #fff;
  font-size: 60px;
  line-height: 1.4;
  position: relative;
  opacity: 10;
}
