.traits {
  margin-top: 5em;
  margin-bottom: 1em;
}

.traits-container {
  max-width: 100vw;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

@media screen and (min-width: 300px) and (max-width: 600px) {
  .traits-container {
    max-width: 90vw;
  }
}

@media screen and (min-width: 620px) and (max-width: 920px) {
  .traits-container {
  }
}
