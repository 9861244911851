.nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 40;
  background: transparent;
}

.hamburger {
  color: yellow;
  display: none;
}

.header .logo {
  position: relative;
  max-width: 350px;
  margin: 0 auto;
}

.header .logo a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: 25px;
  transition: all 0.3s;
  color: white;
  text-shadow: 0 0 3px yellow, 0 0 5px #0000ff;
}

.nav-links {
  display: flex;
  justify-content: center;
  gap: 4em;
}

.nav-links a {
  font-size: 1.4em;
  font-weight: bold;
  transition: all 0.4s;
  cursor: pointer;
}

.invisible {
  display: none;
}

@media screen and (min-width: 620px) and (max-width: 920px) {
  .nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 40;
    background: transparent;
  }

  .hamburger {
    display: block;
    font-size: 35px;
    color: white;
    position: absolute;
    top: 0;
    margin-top: 10px;
    left: 0;
    margin-left: 8px;

    z-index: 500;
  }

  .logo a {
    font-size: 26px;
    color: lightgray;
    font-weight: bold;
  }

  .invisible .logo.active a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    font-size: 40px;
  }

  .nav-links {
    display: none;
  }

  .invisible {
    display: block;
  }

  .nav-links.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1e282e;
    width: 100%;
    height: 20vh;
    position: absolute;
    top: 0;
  }

  .nav-links.active a {
    font-size: 20px;
    color: lightgray;
  }

  .invisible .socials {
    display: flex;
    gap: 5em;
  }
}

.header-section {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 111;
  padding-top: 10px;
  padding-bottom: 20px;
}

.main-menu {
  float: right;
}

.main-menu ul {
  list-style: none;
}

.main-menu ul li {
  display: inline;
}

.main-menu ul li a {
  display: inline-block;
  text-transform: uppercase;
  font-size: 14px;
  color: #fff;
  padding: 15px;
  margin-right: 10px;
  position: relative;
}

.main-menu ul li a:after {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  background: #fff;
  left: 50%;
  top: 26px;
  margin-left: -17px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  opacity: 0;
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.main-menu ul li a:hover:after {
  opacity: 1;
  width: 34px;
}

@media only screen and (min-width: 350px) and (max-width: 600px) {
  .nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 40;
    background: transparent;
  }

  .hamburger {
    display: block;
    font-size: 25px;
    color: white;
    position: absolute;
    top: 0;
    margin-top: 10px;
    left: 0;
    margin-left: 8px;

    z-index: 500;
  }

  .logo a {
    font-size: 26px;
    color: lightgray;
    font-weight: bold;
  }

  .invisible .logo.active a {
    font-size: 30px;
  }

  .nav-links {
    display: none;
  }

  .invisible {
    display: block;
  }

  .nav-links.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1e282e;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
  }

  .nav-links.active a {
    font-size: 17px;
    color: lightgray;
  }
}
