.contract-address {
  display: flex;
  gap: 1em;
  font-weight: bold;
}
.contract-address a {
  color: #01b1fd;
}

.overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 222; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.overlay div {
  margin: auto;
  border-radius: 1em;
  border-color: #01b1fda2;
  border-style: double;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 2em 3em;
}

.overlay h2 {
  color: black;
}

.overlay span {
  color: black;
  font-weight: bold;
  margin: auto;
  background-color: #01b1fd;
  padding: 1em 3em;
  border-radius: 2em;
}

.overlay a {
  margin: auto;
  margin-top: 2em;
}

.overlay i {
  color: red;
  align-self: flex-end;
}

.overlay img {
  align-self: center;
  margin: 2em;
}

.overlay p {
  align-self: center;
  color: black;
  font-weight: bold;
}

@media screen and (max-width: 1028px) {
  .overlay div {
    margin-top: 5em;
    border-radius: 1em;
    border-color: #01b1fda2;
    border-style: double;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 2em 3em;
  }
}

@media screen and (max-width: 1028px) {
  .contract-address {
    flex-direction: column;
    gap: 0.1em;
  }
}
